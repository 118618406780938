import React from 'react';
import './WebPage.css';
import {
    Switch,
    Route
} from 'react-router-dom';

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./Home";
import Service from "./Service";
import Booking from "./Booking";
import Contact from "./Contact";
import Praxis from "./Praxis";
import Impressum from "./Impressum";
import Privacy from "./Privacy";

const WebPage = () => {

    return (
        <div className={"pageMain"}>
            <Header />
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/leistungen">
                    <Service />
                </Route>
                <Route path="/buchung">
                    <Booking />
                </Route>
                <Route path="/kontakt">
                    <Contact />
                </Route>
                <Route path="/praxis">
                    <Praxis />
                </Route>
                <Route path="/impressum">
                    <Impressum />
                </Route>
                <Route path="/datenschutz">
                    <Privacy />
                </Route>
            </Switch>
            <Footer />
        </div>
    )
};

export default WebPage;
