import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBwIeNvOM5G5TyVs2FPUxYR2awTOgn204E",
    authDomain: "fusspflege-eliane.firebaseapp.com",
    databaseURL: "https://fusspflege-eliane.firebaseio.com",
    projectId: "fusspflege-eliane",
    storageBucket: "fusspflege-eliane.appspot.com",
    messagingSenderId: "15099455006",
    appId: "1:15099455006:web:3b4282a69de054c278b00f",
    measurementId: "G-5J2L8J2HX9"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const getFreeAppointments = async (month, year) => {
    const firstDay = new Date(year, month, 0, 0, 0, 0, 0);
    const lastDay = new Date(year, month + 1, 0, 0, 0, 0, 0);
    const data = await db.collection("slots")
        .where("date", ">=", firebase.firestore.Timestamp.fromDate(firstDay))
        .where("date", "<=", firebase.firestore.Timestamp.fromDate(lastDay))
        .get()
        .then(snapshot => {
            const dataArray = [];
            snapshot.forEach(doc => {
                const docData = doc.data()
                docData.id = doc.id;
                dataArray.push(docData);
            });
            return dataArray;
        })
        .catch(() => []);
    if (data.length === 0) return [];
    return data;
};

export const saveAppointment = async (data, slot) => {
    const { email, package: dataPackage, name, surname, phone, date, day, time } = data;
    const { massage, lack, lack_french } = data;
    let appointmentDate = date._d;
    let timeSplit = time.split(":");
    appointmentDate.setHours(timeSplit[0], timeSplit[1], 0, 0);
    appointmentDate = firebase.firestore.Timestamp.fromDate(appointmentDate);
    let optionalPackages = "";
    if (massage) {
        optionalPackages += "Fußmassage, ";
    }
    if (lack) {
        optionalPackages += "Nagellack, ";
    }
    if (lack_french) {
        optionalPackages += "Nagellack French-Weiß, ";
    }
    const userData = {
        date: appointmentDate,
        day,
        time,
        email,
        name,
        surname,
        phone,
        package: dataPackage,
        optionalPackages
    };
    const { id: clientId } = await db.collection("appointments").add(userData);

    if (clientId !== undefined) {
        const timeData = slot.times.map(t => {
            if (t.time === time) {
                return {
                    time,
                    busy: true
                };
            } else return t;
        });
        const updatedData = await db.collection("slots").doc(slot.id).update({
            times: timeData
        })
            .then(() => true)
            .catch(() => false);
        return updatedData;
    }
    return false;
};


/*
 * Admin Portal Functions
 */
export const logIn = async (email, pwd) => {
    return await firebase.auth().signInWithEmailAndPassword(email, pwd)
        .then(() => true)
        .catch(() => false);
};

export const logOut = async () => {
    return await firebase.auth().signOut()
        .then(() => true)
        .catch(() => false);
};

// Return Slots and Appointments for Date
export const getDay = async (date) => {
    const [slots] = await db.collection("slots")
        .where("day", "==", date)
        .get()
        .then(snapshot => {
            const temp = [];
            snapshot.forEach(doc => {
                const docData = doc.data()
                docData.id = doc.id;
                temp.push(docData);
            });
            return temp;
        })
        .catch(() => [undefined]);
    // { id, date, day, times (busy, time) }
    if (slots === undefined) {
        return [];
    }

    const appointments = await db.collection("appointments")
        .where("day", "==", date)
        .get()
        .then(snapshot => {
            const temp = [];
            snapshot.forEach(doc => {
                const docData = doc.data()
                docData.id = doc.id;
                temp.push(docData);
            });
            return temp;
        })
        .catch(() => []);

    slots.times = slots.times.map(s => {
        if (!s.busy) {
            return s;
        } else {
            s.appointment = appointments.find(a => a.time === s.time);
            return s;
        }
    });
    return slots;
};

// { id, date, day, times (busy, time, appointment) }
export const addSlot = async (slots, time, date) => {
    if (slots.times === undefined) {
        date.setHours(0, 0, 0, 0);
        const newSlot = await db.collection("slots").add({
            day: formatDate(date),
            date: firebase.firestore.Timestamp.fromDate(date),
            times: []
        })
            .then(doc => {
                return {
                    id: doc.id,
                    day: formatDate(date),
                    date: firebase.firestore.Timestamp.fromDate(date),
                    times: []
                }
            })
            .catch(() => {
                return {
                    id: "-",
                    times: [],
                    day: formatDate(date),
                    date: date
                }
            });
        slots = newSlot;
    }
    const newSlots = [...slots.times];
    newSlots.push({
        busy: false,
        time: time
    });
    const addSlotSuccess = await db.collection("slots").doc(slots.id)
        .update({
            times: newSlots
        })
        .then(() => true)
        .catch(() => false);
    if (addSlotSuccess) {
        slots.times = newSlots;
        return slots;
    }
    return slots;
};

export const removeSlot = async (slots, time) => {
    let newSlots = [...slots.times];
    const hasAppointment = newSlots.findIndex(t => t.time === time);
    if (hasAppointment !== -1 && newSlots[hasAppointment].appointment !== undefined) {
        // If appointments exists, remove it first
        await db.collection("appointments").doc(newSlots[hasAppointment].appointment.id).delete();
    }
    newSlots = newSlots.filter(s => s.time !== time);
    const removeSlotSuccess = await db.collection("slots").doc(slots.id)
        .update({
            times: newSlots
        })
        .then(() => true)
        .catch(() => false);
    if (removeSlotSuccess) {
        slots.times = newSlots;
        return slots;
    }
    return slots;
};

export const fillSlot = async (slots, time, data) => {
    const { name, surname, email = "", phone, slotPackage, massage, lack, lackFrench } = data;
    let newSlots = [...slots.times];
    let optionalPackages = "";
    if (massage) {
        optionalPackages += "Fußmassage, ";
    }
    if (lack) {
        optionalPackages += "Nagellack, ";
    }
    if (lackFrench) {
        optionalPackages += "Nagellack French-Weiß, ";
    }
    const newAppointment = {
        day: slots.day,
        time: time,
        name,
        surname,
        email,
        phone,
        package: slotPackage,
        optionalPackages
    };
    const newAppointmentId = await db.collection("appointments")
        .add(newAppointment)
        .then(doc => doc.id)
        .catch(() => false);
    if (!newAppointmentId) {
        return slots;
    }
    newAppointment.id = newAppointmentId;
    newSlots = newSlots.map(s => {
        if (s.time === time) {
            s.busy = true;
            s.appointment = newAppointment;
        }
        return s;
    })
    const fillSlotSuccess = await db.collection("slots").doc(slots.id)
        .update({
            times: newSlots
        })
        .then(() => true)
        .catch(() => false);
    if (fillSlotSuccess) {
        slots.times = newSlots;
        return slots;
    }
    return slots;
};

export const emptySlot = async (slots, time) => {
    let newSlots = [...slots.times];
    const hasAppointment = newSlots.findIndex(t => t.time === time);
    if (hasAppointment !== -1 && newSlots[hasAppointment].appointment !== undefined) {
        // TODO: was undefined
        await db.collection("appointments").doc(newSlots[hasAppointment].appointment.id).delete();
    }
    newSlots = newSlots.map(s => {
        if (s.time === time) {
            delete s.appointment;
            s.busy = false;
            return s;
        }
        return s;
    });
    const emptySlotSuccess = await db.collection("slots").doc(slots.id)
        .update({
            times: newSlots
        })
        .then(() => true)
        .catch(() => false);
    if (emptySlotSuccess) {
        slots.times = newSlots;
        return slots;
    }
    return slots;
};

export const moveSlot = async (slots, time, data) => {
    const { newDay, newTime } = data;

    // Change Appointment
    const clientAppointment = slots.times.find(t => t.time === time).appointment;
    const clientAppointmentId = clientAppointment.id;
    clientAppointment.time = newTime;
    clientAppointment.day = newDay;
    delete clientAppointment.id;
    await db.collection("appointments").doc(clientAppointmentId)
        .update(clientAppointment);

    // Set New Slot busy and Add Appointment
    let [newSlot] = await db.collection("slots")
        .where("day", "==", newDay)
        .get()
        .then(snapshot => {
            const temp = [];
            snapshot.forEach(doc => {
                const docData = doc.data()
                docData.id = doc.id;
                temp.push(docData);
            });
            return temp;
        })
        .catch(() => [undefined]);
    if (newSlot !== undefined) {
        newSlot.times = newSlot.times.map(t => {
            if (t.time === newTime) {
                t.appointment = clientAppointment;
                t.busy = true;
            }
            return t;
        })
        await db.collection("slots").doc(newSlot.id)
            .update({
                times: newSlot.times
            });
    }

    // Change Current Slots
    let newSlots = slots.day === newDay ? [...newSlot.times] : [...slots.times];
    newSlots = newSlots.map(s => {
        if (s.time === time) {
            delete s.appointment;
            s.busy = false;
            return s;
        }
        return s;
    });
    const moveSlotSuccess = await db.collection("slots").doc(slots.id)
        .update({
            times: newSlots
        })
        .then(() => true)
        .catch(() => false);
    if (moveSlotSuccess) {
        slots.times = newSlots;
        return slots;
    }
    return slots;
};

/*
 * Helper functions following
 */
export const formatDate = (currentDate) => {
    let formatDay = currentDate.getDate();
    let formatMonth = currentDate.getMonth() + 1;
    let date = `${currentDate.getFullYear()}-`;
    if (formatMonth < 10) {
        date += `0${formatMonth}-`;
    } else {
        date += `${formatMonth}-`;
    }
    if (formatDay < 10) {
        date += `0${formatDay}`;
    } else {
        date += `${formatDay}`;
    }
    return date;
};

export const deleteDates = async () => {
    const today = new Date(2022, 5, 1);
    await db.collection("slots")
        .where("date", ">=", firebase.firestore.Timestamp.fromDate(today))
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => doc.ref.delete())
        })
        .catch(() => []);
}

export const createDates = async () => {
    // mo-fr 09:00, 10:30, 18:00
    // every 2nd sat 9:30, 11:00
    const today = new Date(2024, 5, 0);
    let secondSaturday = false;
    today.setHours(0, 0, 0, 0);
    for (let i = 0; i < 365; i++) {
        today.setDate(today.getDate() + 1);
        if (today.getDay() > 0 && today.getDay() < 6) {
            const data = {
                date: firebase.firestore.Timestamp.fromDate(today),
                day: formatDate(today),
                times: [
                    {
                        time: "09:00",
                        busy: false
                    },
                    {
                        time: "10:30",
                        busy: false
                    },
                    {
                        time: "14:15",
                        busy: false
                    },
                    {
                        time: "15:30",
                        busy: false
                    },
                    {
                        time: "17:00",
                        busy: false
                    }
                ]
            };
            await db.collection("slots").add(data);
        } else if (today.getDay() === 6) {
            // if (secondSaturday) {
            //     const data = {
            //         date: firebase.firestore.Timestamp.fromDate(today),
            //         day: formatDate(today),
            //         times: [
            //             {
            //                 time: "09:30",
            //                 busy: false
            //             },
            //             {
            //                 time: "11:00",
            //                 busy: false
            //             }
            //         ]
            //     };
            //     secondSaturday = !secondSaturday;
            //     await db.collection("slots").add(data);
            // } else {
            //     secondSaturday = !secondSaturday;
            // }
        }
    }
};
