import React, { useEffect } from 'react';
import { Typography, makeStyles } from "@material-ui/core";

function Impressum() {
    const classes = useStyle();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={classes.main}>
            <Typography className={classes.bold}>
                Impressum
            </Typography>
            <Typography className={classes.body}>
                Angaben gemäß § 5 TMG
            </Typography>
            <Typography className={classes.bold}>
                Fußpflege Eliane <br/>
                Eliane Böving <br/>
                Linsenrain 8 <br/>
                97816 Lohr am Main <br/><br/>
                Kontakt <br/>
                Telefon: 09352 8889908 <br/>
                Mobil: 0152 54177806 <br/>
                E-Mail: kontakt@fusspflege-eliane.de <br/><br/>
            </Typography>

            <Typography className={classes.bold}>
                Verbraucherstreitbeilegung / Universalschlichtungsstelle
            </Typography>
            <Typography className={classes.body}>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </Typography>

            <Typography className={classes.bold}>
                Haftung für Inhalte
            </Typography>
            <Typography className={classes.body}>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. <br/>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </Typography>

            <Typography className={classes.bold}>
                Haftung für Links
            </Typography>
            <Typography className={classes.body}>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle er verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </Typography>

            <Typography className={classes.bold}>
                Urheberrecht
            </Typography>
            <Typography className={classes.body}>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. <br/>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </Typography>

        </div>
    );
}

const useStyle = makeStyles(theme => ({
    main: {
        [theme.breakpoints.down("lg")]: {
        },
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0
        }
    },
    bold: {
        fontWeight: 600,
        fontSize: 13
    },
    body: {
        fontWeight: 300,
        fontSize: 13,
        marginBottom: 13,
        textAlign: "justify"
    }
}));

export default Impressum;
