import React, { useEffect, useState } from 'react';
import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    makeStyles, IconButton, Snackbar
} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import MetaTags from "react-meta-tags";
import CloseIcon from "@material-ui/icons/Close";

const snackMessage = "Vom 12.12.2022 bis zum 15.01.2023 ist die Praxis aufgrund von Urlaub geschlossen und telefonisch nicht erreichbar.\n\nTermine sind während dem Zeitraum ausschließlich online auf der Homepage buchbar.";

function Contact() {
    const classes = useStyles();

    const [snackOpen, setSnackOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        const contactPicture = document.getElementById("contactPicture");
        contactPicture.onload = () => {
            contactPicture.style.display = "inherit";
        };
    }, []);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <div>
            <MetaTags>
                <meta name={"description"} content={"Kontakt und Anfahrt."} />
                <meta name={"og:title"} content={"Kontakt"} />
            </MetaTags>
            <Snackbar
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              open={snackOpen}
              message={snackMessage}
              onClose={handleSnackClose}
              action={
                  <IconButton size={"small"} className={classes.snackbar} onClick={handleSnackClose}>
                      <CloseIcon fontSize={"small"} />
                  </IconButton>
              }
              className={classes.snack}
            />
            <div className={"contactBody"}>
                <Grid container spacing={2} alignItems={"center"} justify={"center"} className={"topElement"}>
                    <Grid item xs={12} md={4}>
                        <div className={"topElement"}>
                            <Typography variant={"overline"} color={"secondary"} className={"welcome"}>
                                Kontakt
                            </Typography>
                        </div>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Fußpflege Eliane"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOnIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Linsenrain 8, 97816 Lohr am Main"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary={"kontakt@fusspflege-eliane.de"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary={"09352 8889908"}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <SmartphoneIcon />
                                </ListItemIcon>
                                <ListItemText primary={"0152 54177806"}/>
                            </ListItem>
                        </List>

                    </Grid>
                    <Grid item xs={12} md={8} alignItems={"flex-start"}>
                        <div className={classes.avatarDiv}>
                            <img alt={""} src={"kontakt.jpg"} id={"contactPicture"} className={classes.avatarImg}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.directions}>
                        <Typography variant={"overline"} color={"secondary"} className={"welcome"}>
                            Öffnungszeiten
                        </Typography>
                        <p>Montag - Mittwoch: 9 - 12 Uhr / 14 - 18 Uhr</p>
                        <p>Donnerstag: 9 - 12 Uhr / 14 - 16 Uhr</p>
                        <p>Freitag: geschlossen</p>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.directions}>
                        <Typography variant={"overline"} color={"secondary"} className={"welcome"}>
                            Anfahrt
                        </Typography>
                        <iframe
                            title={"Fusspflege Eliane"}
                            className={classes.karte}
                            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyD41wX6ubroPywooumVdMj9iw_PIinl2T0&q=Linsenrain 8, Lohr am Main"}
                            frameBorder={0}
                            style={{border: 0}}
                            height={300}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    karte: {
        maxHeight: 400,
        width: "100%"
    },
    avatarDiv: {
        height: 250
    },
    avatarImg: {
        display: "none",
        backgroundColor: "white",
        padding: 6,
        border: "3px solid #4b9426",
        borderRadius: "50%",
        height: "100%",
        width: "auto"
    },
    directions: {
        marginTop: 50
    },
    snack: {
        maxWidth: 400,
        textAlign: "justify",
        whiteSpace: "pre-line"
    },
    snackbar: {
        color: "#fff"
    },
}));

export default Contact;
