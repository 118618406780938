import React from "react";
import { Button, Link, Typography, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={"footer"}>
            <div className={"rightsButtons"}>
                <Button key={"impressum"} underline="none" component={NavLink} exact to={"/impressum"}>
                    <Typography className={classes.link} variant={"caption"}>Impressum</Typography>
                </Button>
                <Typography className={classes.link} variant={"caption"}>|</Typography>
                <Button key={"datenschutz"} underline="none" component={NavLink} exact to={"/datenschutz"}>
                    <Typography className={classes.link} variant={"caption"}>Datenschutz</Typography>
                </Button>
            </div>
            <div>
                <Typography className={classes.arr}>
                    © {new Date().getFullYear()} Fußpflege Eliane. All Rights Reserved.
                </Typography>
                <Typography className={classes.arr}>
                    Medizinische und kosmetische Fußpflegebehandlung - Lohr am Main
                </Typography>
                <Typography className={classes.dev}>
                    Developed by <Link underline={"none"} className={classes.tp} href={"https://tomaspolacek.com/"}>Tomáš Poláček</Link>
                </Typography>
            </div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    arr: {
        width: "100%",
        color: "#555",
        fontSize: 12,
        fontWeight: 300,
    },
    dev: {
        width: "100%",
        color: "#555",
        fontSize: 12,
        fontWeight: 300,
        marginBottom: 40
    },
    link: {
        fontSize: 10
    },
    tp: {
        transition: "color 250ms",
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}));

export default Footer;
