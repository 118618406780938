import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
    makeStyles, FormControlLabel, Checkbox
} from "@material-ui/core";

const packages = [
    {
        header: "Hauptleistung",
        items: ["Fußbehandlung Basis", "Fußbehandlung Medizinisch", "Fußbehandlung SPA"]
    }
]

export default function FillSlot(props) {
    const classes = useStyles();
    const {open, onFill, onClose, time} = props;
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [slotPackage, setSlotPackage] = useState("");
    const [massage, setMassage] = useState(false);
    const [lack, setLack] = useState(false);
    const [lackFrench, setLackFrench] = useState(false);

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{className: classes.dialogContainer}}>
            <DialogTitle>Termin anlegen</DialogTitle>
            <DialogContent className={classes.dialogRoot}>
                <div className={classes.grid}>
                    <div className={classes.item}>
                        <TextField
                            label={"Vorname"}
                            variant={"outlined"}
                            color={"secondary"}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className={classes.item}>
                        <TextField
                            label={"Name"}
                            variant={"outlined"}
                            color={"secondary"}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                    </div>
                    <div className={classes.item}>
                        <TextField
                            label={"Telefon"}
                            variant={"outlined"}
                            color={"secondary"}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className={classes.item}>
                        <TextField
                            label={"E-Mail"}
                            variant={"outlined"}
                            color={"secondary"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className={classes.item}>
                        <FormControl className={classes.formControl}>
                            <InputLabel variant={"outlined"}>Leistung</InputLabel>
                            <Select
                                value={slotPackage}
                                onChange={(e) => setSlotPackage(e.target.value)}
                                variant={"outlined"}
                                color={"secondary"}
                            >
                                {
                                    packages.map(p => {
                                        return [
                                            <ListSubheader className={classes.subheader}>{p.header}</ListSubheader>,
                                            p.items.map(i => (
                                                <MenuItem className={classes.menuItem} value={i}>{i}</MenuItem>
                                            ))
                                        ]
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.item}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"massage"}
                                    value={massage}
                                    onChange={e => setMassage(e.target.checked)}
                                />
                            }
                            label={"Fußmassage"}
                        />
                    </div>
                    <div className={classes.item}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"lack"}
                                    value={lack}
                                    onChange={e => setLack(e.target.checked)}
                                />
                            }
                            label={"Nägel lackieren"}
                        />
                    </div>
                    <div className={classes.item}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"lackFrench"}
                                    value={lackFrench}
                                    onChange={e => setLackFrench(e.target.checked)}
                                />
                            }
                            label={"Nägel lackieren mit French-Weiß"}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Zurück
                </Button>
                <Button onClick={() => onFill(time, {name, surname, phone, email, slotPackage, massage, lack, lackFrench})}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        minWidth: 310
    },
    dialogRoot: {
        padding: 0,
    },
    formControl: {
        width: "100%",
    },
    grid: {
        fontFamily: "Roboto, sans-serif !important",
        display: "grid",
        gridAutoColumns: "1fr",
    },
    item: {
        width: "75%",
        justifySelf: "center",
        alignSelf: "center",
        margin: "10px",
    },
    menuItem: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            whiteSpace: "normal"
        }
    },
    subheader: {
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        }
    },
}));
