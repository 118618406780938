import React, { useState } from "react";
import { Button, Menu, MenuItem, makeStyles, useMediaQuery } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from "react-router-dom";

const navLinks = [
    { name: "Startseite", to: "/" },
    { name: "Leistungen", to: "/leistungen" },
    { name: "Online Buchung", to: "/buchung" }
];
const navLinks2 = [
    { name: "Praxis", to: "/praxis" },
    { name: "Kontakt & Anfahrt", to: "/kontakt" }
];

const Navigation = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width: 960px)");
    const [menuOpen, setMenuOpen] = useState(null);

    const handleOpen = (e) => {
        setMenuOpen(e.currentTarget);
    }

    const handleClose = () => {
        setMenuOpen(null);
    }

    return (
        matches ? (
            <div className={"menu"}>
                {
                    navLinks.map(n => (
                        <Button key={n.name} className={classes.button} underline={"none"} component={NavLink} exact to={n.to}>
                            {n.name}
                        </Button>
                    ))
                }
                {
                    navLinks2.map(n => (
                        <Button key={n.name} className={classes.button} underline={"none"} component={NavLink} exact to={n.to}>
                            {n.name}
                        </Button>
                    ))
                }
            </div>
        ) : (
            <div className={"nav"}>
                <Button aria-controls={"menu"} aria-haspopup={"true"} onClick={handleOpen}>
                    <MenuIcon />
                </Button>
                <Menu
                    id={"menu"}
                    anchorEl={menuOpen}
                    open={Boolean(menuOpen)}
                    keepMounted
                    onClose={handleClose}
                >
                    {
                        navLinks.map(n => (
                            <MenuItem key={n.name} component={NavLink} exact to={n.to} onClick={handleClose}>{n.name}</MenuItem>
                        ))
                    }
                    {
                        navLinks2.map(n => (
                            <MenuItem key={n.name} component={NavLink} exact to={n.to} onClick={handleClose}>{n.name}</MenuItem>
                        ))
                    }
                </Menu>
            </div>
        )
    );
};

const useStyles = makeStyles(theme => ({
    button: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 1.5,
        color: theme.palette.primary.light,
        '&:hover': {
            color: theme.palette.secondary.main,
            background: "none"
        },
        '&.active': {
            color: theme.palette.secondary.main,
            background: "none"
        },
        transition: "color 250ms"
    },
    smallButton: {
        textTransform: "none"
    },
    input: {
        display: 'none',
    },
}));

export default Navigation;
