import React from "react";
import { Link } from "@material-ui/core";
import logo from "../../img/fusspflege-logo.png";
import Navigation from "./Navigation";

const Header = () => {
    return (
        <div className={"header"}>
            <div id={"logo"}>
                <h1 id={"headerName"}>Fußpflege Eliane</h1>
                <Link href={"/"}>
                    <img className={"logo"} src={logo} alt={""} />
                </Link>
            </div>
            <div className={"divider"} />
            <Navigation />
        </div>
    )
};

export default Header;
