import React, {useEffect, useState} from "react";
import {
    AppBar,
    Button,
    CircularProgress,
    Typography,
    makeStyles
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import WorkIcon from '@material-ui/icons/Work';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import moment from "moment";
import "moment/locale/de";
import MomentUtils from "@date-io/moment";
import {
    formatDate,
    getDay,
    addSlot,
    removeSlot,
    fillSlot,
    moveSlot,
    emptySlot, createDates
} from "../functions/firebase";
import AddSlot from "./components/AddSlot";
import RemoveSlot from "./components/RemoveSlot";
import EmptySlot from "./components/EmptySlot";
import FillSlot from "./components/FillSlot";
import MoveSlot from "./components/MoveSlot";

const SlotComponent = (props) => {
    const { removeSlot, fillSlot, emptySlot, moveSlot } = props;
    const { classes, busy, time: slotTime, appointment = {} } = props;
    const { name, surname, email = "", phone, package: servicePackage, optionalPackages } = appointment;
    return (
        <div className={classes.slotContainer}>
            <div className={classes.slotBusy}>
                {
                    busy ? (
                        <WorkIcon/>
                    ) : (
                        <WorkOffIcon color={"secondary"}/>
                    )
                }
            </div>
            <div className={classes.slotTime}>
                {slotTime}
            </div>
            <div className={classes.slotDetails}>
                <div>
                    {name} {surname}
                </div>
                <div>
                    {servicePackage}
                </div>
                <div>
                    {optionalPackages}
                </div>
                <div>
                    {phone}
                </div>
                <div>
                    {email}
                </div>
            </div>
            <div className={classes.slotActionsContainer}>
                <div className={classes.slotActions}>
                    <div className={classes.slotActionButton}>
                        <Button variant={"contained"} disableElevation disabled={busy} onClick={() => fillSlot(slotTime)}>
                            Hinzufügen
                        </Button>
                    </div>
                    <div className={classes.slotActionButton}>
                        <Button variant={"contained"} disableElevation disabled={!busy} onClick={() => emptySlot(slotTime)}>
                            Entfernen
                        </Button>
                    </div>
                    <div className={classes.slotActionButton}>
                        <Button variant={"contained"} disableElevation disabled={!busy} onClick={() => moveSlot(slotTime)}>
                            Verschieben
                        </Button>
                    </div>
                    <div className={classes.slotActionButton}>
                        <Button variant={"contained"} disableElevation onClick={() => removeSlot(slotTime)}>
                            Löschen
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default function Portal(props) {
    moment.locale("de");
    const classes = useStyles();
    const { logOut } = props;

    const [addOpen, setAddOpen] = useState(false);
    const [removeOpen, setRemoveOpen] = useState(false);
    const [emptyOpen, setEmptyOpen] = useState(false);
    const [fillOpen, setFillOpen] = useState(false);
    const [moveOpen, setMoveOpen] = useState(false);
    const [slotTime, setSlotTime] = useState(null);
    const [loading, setLoading] = useState(true);

    const [date, setDate] = useState(new Date());
    const [slots, setSlots] = useState({});

    useEffect(() => {
        handleChangeDate(new moment()).then();
    }, []);

    const handleChangeDate = async (momentDate) => {
        setDate(momentDate._d);
        const daySlots = await getDay(momentDate.format("YYYY-MM-DD"));
        setSlots(daySlots);
        setLoading(false);
    };

    const handleAddSlot = async (time) => {
        const newSlots = await addSlot(slots, time, date);
        setSlots(newSlots);
        setAddOpen(false);
    };

    const handleRemoveSlot = async (time) => {
        const newSlots = await removeSlot(slots, time);
        setSlots(newSlots);
        setRemoveOpen(false);
    };

    const handleFillSlot = async (time, data) => {
        const newSlots = await fillSlot(slots, time, data);
        setSlots(newSlots);
        setFillOpen(false);
    };

    const handleEmptySlot = async (time) => {
        const newSlots = await emptySlot(slots, time);
        setSlots(newSlots);
        setEmptyOpen(false);
    };

    const handleMoveSlot = async (time, data) => {
        const newSlots = await moveSlot(slots, time, data);
        setSlots(newSlots);
        setMoveOpen(false);
    };

    const openAddSlot = () => {
        setAddOpen(true);
    }

    const closeAddSlot = () => {
        setAddOpen(false);
    }

    const openRemoveSlot = (time) => {
        setSlotTime(time);
        setRemoveOpen(true);
    }

    const closeRemoveSlot = () => {
        setRemoveOpen(false);
    }

    const openEmptySlot = (time) => {
        setSlotTime(time);
        setEmptyOpen(true);
    }

    const closeEmptySlot = () => {
        setEmptyOpen(false);
    }

    const openFillSlot = (time) => {
        setSlotTime(time);
        setFillOpen(true);
    }

    const closeFillSlot = () => {
        setFillOpen(false);
    }

    const openMoveSlot = (time) => {
        setSlotTime(time);
        setMoveOpen(true);
    }

    const closeMoveSlot = () => {
        setMoveOpen(false);
    }

    return (
        <div className={classes.main}>
            {/*<Button onClick={()=>createDates()}>Create Dates</Button>*/}
            <AddSlot open={addOpen} onAdd={handleAddSlot} onClose={closeAddSlot}/>
            <RemoveSlot open={removeOpen} time={slotTime} onRemove={handleRemoveSlot} onClose={closeRemoveSlot}/>
            <EmptySlot open={emptyOpen} time={slotTime} onEmpty={handleEmptySlot} onClose={closeEmptySlot}/>
            <FillSlot open={fillOpen} time={slotTime} onFill={handleFillSlot} onClose={closeFillSlot}/>
            <MoveSlot open={moveOpen} time={slotTime} onMove={handleMoveSlot} onClose={closeMoveSlot}/>
            <AppBar position={"static"} className={classes.appBar}>
                <Button onClick={() => logOut()} className={classes.appBarButton}>Ausloggen</Button>
            </AppBar>
            <div className={classes.container}>
                <div className={classes.itemCalendar}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            disableToolbar
                            autoOk
                            orientation="landscape"
                            variant="static"
                            openTo="date"
                            value={date}
                            onChange={handleChangeDate}
                            inputStyle={{ textAlign: 'center' }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className={classes.itemData}>
                    <Typography className={classes.day}>
                        {`${formatDate(date).split("-")[2]}.${formatDate(date).split("-")[1]}.${formatDate(date).split("-")[0]}`}
                    </Typography>
                    {
                        slots.times ? (
                            <div>
                                {
                                    slots.times.length > 0 ? (
                                        slots.times.sort((a, b) => a.time >= b.time).map(t => (
                                            <SlotComponent
                                                key={t.time}
                                                classes={classes}
                                                busy={t.busy}
                                                time={t.time}
                                                appointment={t.appointment}
                                                removeSlot={openRemoveSlot}
                                                emptySlot={openEmptySlot}
                                                fillSlot={openFillSlot}
                                                moveSlot={openMoveSlot}
                                            />
                                        ))
                                    ) : (
                                        <Typography>
                                            Keine Termine
                                        </Typography>
                                    )
                                }
                            </div>
                        ) : (
                            <div>
                                {
                                    loading ? (
                                        <CircularProgress color={"secondary"} />
                                    ) : (
                                        <Typography>
                                            Keine Termine
                                        </Typography>
                                    )
                                }
                            </div>
                        )
                    }
                    <div className={classes.actionButton}>
                        <Button variant={"contained"} disableElevation color={"primary"} onClick={() => openAddSlot()}>
                            Anlegen
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    appBar: {
        textAlign: "right",
        display: "block",
        marginTop: 0,
        marginBottom: 10
    },
    appBarButton: {
        color: "#fff",
        fontSize: 11,
        margin: "2px 10px"
    },
    container: {
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "[calendar] auto [data] auto"
    },
    day: {
        marginBottom: 10
    },
    itemCalendar: {
        justifySelf: "center",
        alignSelf: "center"
    },
    itemData: {
        width: "100%",
        textAlign: "center",
        justifySelf: "center",
        alignSelf: "center"
    },
    actionButton: {
        display: "grid",
        gridAutoColumns: "1fr 1fr 1fr",
        margin: "10px 0 30px 0",
        '& button': {
            gridColumn: 3,
            width: "80%",
            justifySelf: "center",
            fontSize: 10,
            maxWidth: 150,
            minHeight: 40
        }
    },
    slotContainer: {
        fontSize: 14,
        display: "grid",
        position: "relative",
        gridTemplateColumns: "15% 15% 70%",
        gridTemplateRows: "auto 20%",
        minHeight: 50,
        paddingBottom: 10,
        marginTop: 10,
        "&::after": {
            content: "\"\"",
            background: "#dfdfdf",
            position: "absolute",
            marginTop: 5,
            bottom: 0,
            left: "5%",
            height: 1,
            width: "90%"
        }
    },
    slotBusy: {
        textAlign: "center",
        justifySelf: "center",
        alignSelf: "center"
    },
    slotTime: {
        textAlign: "center",
        justifySelf: "left",
        alignSelf: "center"
    },
    slotDetails: {
        textAlign: "left",
        width: "100%",
        justifySelf: "center",
        alignSelf: "center",
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "auto auto auto"
    },
    slotActionsContainer: {
        textAlign: "center",
        alignSelf: "center",
        marginTop: 10,
        gridColumn: "1 / span 3",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
    },
    slotActions: {
        gridColumn: "2 / span 2",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto auto",
    },
    slotActionButton: {
        justifySelf: "center",
        width: "80%",
        maxWidth: 150,
        marginBottom: 10,
        '& button': {
            width: "100%",
            minHeight: 40,
            fontSize: 10
        }
    }
}));
