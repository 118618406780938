import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import MetaTags from "react-meta-tags";

import { Typography, Button, makeStyles } from "@material-ui/core";

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

function Home() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div>
            <MetaTags>
                <meta name={"description"} content={"Zertifizierte Fußpflege und Pediküre in Lohr am Main. Medizinische bzw. kosmetische Behandlung. Termin online oder telefonisch vereinbaren."} />
                <meta name={"og:title"} content={"Fußpflege Eliane - Lohr am Main"} />
                <meta name={"og:image"} content={"https://fusspflege-eliane.de/logo512.png"} />
            </MetaTags>

            <div className={"main"}>
                <div className={"gallery"}>
                    <Carousel
                        hasThumbnails={false}
                        hasRightButton={false}
                        hasLeftButton={false}
                        isAutoPlaying={true}
                        hasMediaButton={false}
                        hasSizeButton={false}
                        hasIndexBoard={false}
                        hasDotButtons={"bottom"}
                        images={[
                            { src: "start1.jpg" },
                            { src: "start2.jpg" },
                            { src: "start3.jpg" },
                        ]}
                    />
                </div>
                <div className={"body"}>
                    <div class={"welcomeText"}>
                        <div>
                            <Typography variant={"overline"} color={"secondary"} className={"welcome"}>
                                Herzlich Willkommen
                            </Typography>
                            <Typography variant={"h2"} className={classes.slogan}>
                                Ihre Füße werden es <br/> Ihnen danken!
                            </Typography>
                            <Typography variant={"body2"} className={classes.intro}>
                                Schön, dass Sie vorbeischauen. <br/><br/>
                                Wenn Ihre Füße krank, beansprucht oder einfach müde sind – besuchen Sie meine Praxis in Lohr am Main. Ich bin zertifizierte Fußpflegerin und kümmere mich professionell um das Wohl und die Gesunderhaltung Ihrer Füße.
                                Vereinbaren Sie für eine medizinische bzw. kosmetische Fußpflegebehandlung gerne direkt online oder telefonisch einen Termin. Ich bin für Sie da und freue mich auf Ihren Besuch.
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <Button component={NavLink} to={"/buchung"} size={"large"} variant={"contained"} color={"secondary"} disableElevation className={classes.button}>
                            Termin online buchen
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    intro: {
        color: "#555",
        fontSize: 18,
        fontWeight: 300,
        textAlign: "justify",
        hyphens: "auto",
        marginTop: 20
    },
    button: {
        borderRadius: 0,
        padding: 20
    },
    slogan: {
        fontSize: 30
    }
}));

export default Home;
