import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

export default function AddSlot(props) {
    const classes = useStyles();
    const { open, onAdd, onClose } = props;
    const [time, setTime] = useState(moment());

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{className: classes.dialogContainer}}>
            <DialogTitle>Termin anlegen</DialogTitle>
            <DialogContent className={classes.dialogRoot}>
                <div className={classes.grid}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardTimePicker
                            autoOk
                            variant={"inline"}
                            inputVariant={"outlined"}
                            placeholder={"09:00"}
                            mask={"__:__"}
                            ampm={false}
                            value={time}
                            onChange={setTime}
                            className={classes.item}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Zurück
                </Button>
                <Button onClick={() => onAdd(time.format("HH:mm"))}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        minWidth: 310
    },
    dialogRoot: {
        padding: 0,
        display: "grid",
        gridAutoColumns: "1fr",
        gridAutoRows: "1fr"
    },
    grid: {
        fontFamily: "Roboto, sans-serif !important",
        width: "70%",
        justifySelf: "center"
    },
    item: {
    }
}));
