import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles } from "@material-ui/core";

export default function RemoveSlot(props) {
    const classes = useStyles();
    const { open, onRemove, onClose, time } = props;

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{className: classes.dialogContainer}}>
            <DialogTitle>Termin löschen</DialogTitle>
            <DialogContent className={classes.dialogRoot}>
                <div className={classes.grid}>
                    <Typography>
                        Termin {time} löschen?
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Zurück
                </Button>
                <Button onClick={() => onRemove(time)}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        minWidth: 310
    },
    dialogRoot: {
        padding: 0,
    },
    grid: {
        fontFamily: "Roboto, sans-serif !important",
        display: "grid",
        gridAutoColumns: "1fr",
        gridAutoRows: "1fr",
        textAlign: "center"
    },
    item: {
    }
}));
