import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles.css';
import theme from './theme.json';
import 'typeface-roboto';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import WebPage from './webpage/WebPage';
import AdminPortal from "./admin/AdminPortal";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

ReactDOM.render(
    <ThemeProvider theme={createMuiTheme(theme)}>
        <Router>
            <Switch>
                <Route path="/admin">
                    <AdminPortal />
                </Route>
                <Route path="/">
                    <WebPage />
                </Route>
            </Switch>
        </Router>
    </ThemeProvider>,
  document.getElementById('root')
);
