import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    makeStyles
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/de";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker } from "@material-ui/pickers";

export default function MoveSlot(props) {
    moment.locale("de");
    const classes = useStyles();
    const { open, onMove, onClose, time } = props;
    const [newDay, setNewDay] = useState(moment());
    const [newTime, setNewTime] = useState(moment());

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{className: classes.dialogContainer}}>
            <DialogTitle>Termin verschieben</DialogTitle>
            <DialogContent className={classes.dialogRoot}>
                <div className={classes.grid}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className={classes.item}>
                            <DatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                openTo="date"
                                inputVariant={"outlined"}
                                label={"Neues Datum"}
                                value={newDay}
                                onChange={setNewDay}
                            />
                        </div>
                        <div className={classes.item}>
                            <KeyboardTimePicker
                                autoOk
                                variant={"inline"}
                                placeholder={"09:00"}
                                mask={"__:__"}
                                ampm={false}
                                inputVariant={"outlined"}
                                label={"Neue Zeit"}
                                value={newTime}
                                onChange={setNewTime}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Zurück
                </Button>
                <Button onClick={() => onMove(time, {newDay: newDay.format("YYYY-MM-DD"), newTime: newTime.format("HH:mm")})}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        minWidth: 310
    },
    dialogRoot: {
        padding: 0,
    },
    grid: {
        fontFamily: "Roboto, sans-serif !important",
        display: "grid",
        gridAutoColumns: "1fr",
    },
    item: {
        width: "75%",
        justifySelf: "center",
        alignSelf: "center",
        margin: "10px",
    }
}));
