import React, { useEffect } from 'react';
import { Typography, makeStyles } from "@material-ui/core";

function Privacy() {
    const classes = useStyle();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={classes.main}>
            <Typography className={classes.bold}>
                Datenschutzerklärung
            </Typography>
            <Typography className={classes.bold}>
                Allgemeiner Hinweis und Pflichtinformationen <br/><br/>
            </Typography>
            <Typography className={classes.bold}>
                Benennung der verantwortlichen Stelle
            </Typography>
            <Typography className={classes.body}>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: <br/>
                Fußpflege Eliane <br/>
                Frau Eliane Böving <br/>
                Linsenrain 8 <br/>
                97816 Lohr am Main <br/><br/>
                Telefon: 09352 8889908 <br/>
                E-Mail: kontakt@fusspflege-eliane.de <br/><br/>
                Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.). <br/><br/>
            </Typography>

            <Typography className={classes.bold}>
                Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </Typography>
            <Typography className={classes.body}>
                Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            </Typography>

            <Typography className={classes.bold}>
                Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
            </Typography>
            <Typography className={classes.body}>
                Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href={"https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"}>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>
            </Typography>

            <Typography className={classes.bold}>
                Recht auf Datenübertragbarkeit
            </Typography>
            <Typography className={classes.body}>
                Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </Typography>

            <Typography className={classes.bold}>
                Recht auf Auskunft, Berichtigung, Sperrung, Löschung
            </Typography>
            <Typography className={classes.body}>
                Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
            </Typography>

            <Typography className={classes.bold}>
                SSL- bzw. TLS-Verschlüsselung
            </Typography>
            <Typography className={classes.body}>
                Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
            </Typography>

            <Typography className={classes.bold}>
                Online Buchung
            </Typography>
            <Typography className={classes.body}>
                Per Online Buchung übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt. <br/>
                Die Verarbeitung der in der Online Buchung eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. <br/>
                Über die Online Buchung übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.
            </Typography>

            <Typography className={classes.bold}>
                Google Maps Plugin
            </Typography>
            <Typography className={classes.body}>
                Wir setzen auf unserer Webseite ein Plugin des Internetdienstes Google Maps ein. Betreiber von Google Maps ist Google Inc., ansässig in den USA, CA 94043, 1600 Amphitheatre Parkway, Mountain View. <br/>
                Indem Sie Google Maps auf unserer Webseite nutzen, werden Informationen über die Nutzung dieser Webseite und Ihre IP-Adresse an einen Google- Server in den USA übermittelt und auch auf diesem Server gespeichert. Wir haben keine Kenntnis über den genauen Inhalt der übermittelten Daten, noch über ihre Nutzung durch Google. Das Unternehmen verneint in diesem Kontext die Verbindung der Daten mit Informationen aus anderen Google-Diensten und die Erfassung personenbezogener Daten. Allerdings kann Google die Informationen an Dritte übermitteln. Wenn Sie Javascript in Ihrem Browser deaktivieren, verhindern Sie die Ausführung von Google Maps. Sie können dann aber auch keine Kartenanzeige auf unserer Webseite nutzen. Mit der Nutzung unserer Webseite erklären Sie Ihr Einverständnis mit der beschriebenen Erfassung und Verarbeitung der Informationen durch Google Inc. Näheres zu den Datenschutzbestimmungen und Nutzungsbedingungen für Google Maps erfahren Sie hier: <a href={"https://www.google.com/intl/de_de/help/terms_maps.html"}>https://www.google.com/intl/de_de/help/terms_maps.html</a>
            </Typography>

            <Typography className={classes.bold}>
                Google Web Fonts
            </Typography>
            <Typography className={classes.body}>
                Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. <br/>
                Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts. <br/>
                Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten. <br/>
                Einzelheiten über Google Web Fonts finden Sie unter: <a href={"https://www.google.com/fonts#AboutPlace:about"}>https://www.google.com/fonts#AboutPlace:about</a> und weitere Informationen in den Datenschutzbestimmungen von Google: <a href={"https://policies.google.com/privacy/partners?hl=de"}>https://policies.google.com/privacy/partners?hl=de</a>
            </Typography>

            <Typography className={classes.bold}>
                Google AdWords und Google Conversion-Tracking
            </Typography>
            <Typography className={classes.body}>
                Unsere Website verwendet Google AdWords. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States. <br/>
                AdWords ist ein Online-Werbeprogramm. Im Rahmen des Online-Werbeprogramms arbeiten wir mit Conversion-Tracking. Nach einem Klick auf eine von G oogle geschaltete Anzeige wird ein Cookie für das Conversion-Tracking gesetzt. Cookies sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Google AdWords Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer. Am Cookie können Google und wir erkennen, dass Sie auf eine Anzeige geklickt haben und zu unserer Website weitergeleitet wurden. <br/>
                Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies sind nicht über Websites von AdWords-Kunden nachverfolgbar. Mit Conversion-Cookies werden Conversion-Statistiken für AdWords-Kunden, die Conversion-Tracking einsetzen, erstellt. Adwords-Kunden erfahren wie viele Nutzer auf ihre Anzeige geklickt haben und auf Seiten mit Conversion-Tracking-Tag weitergeleitet wurden. AdWords-Kunden erhalten jedoch keine Informationen, die eine persönliche Identifikation der Nutzer ermöglichen. Wenn Sie nicht am Tracking teilnehmen möchten, können Sie einer Nutzung widersprechen. Hier ist das Conversion-Cookie in den Nutzereinstellungen des Browsers zu deaktivieren. So findet auch keine Aufnahme in die Conversion-Tracking Statistiken statt. <br/>
                Die Speicherung von "Conversion-Cookies" erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir als Websitebetreiber haben ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und unsere Werbung zu optimieren. <br/>
                Einzelheiten zu Google AdWords und Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von Google: <a href={"https://www.google.de/policies/privacy/"}>https://www.google.de/policies/privacy/</a> <br/>
                Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.
            </Typography>



        </div>
    );
}

const useStyle = makeStyles(theme => ({
    main: {
        [theme.breakpoints.down("lg")]: {
        },
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0
        }
    },
    bold: {
        fontWeight: 600,
        fontSize: 13
    },
    body: {
        fontWeight: 300,
        fontSize: 13,
        marginBottom: 13,
        textAlign: "justify"
    }
}));

export default Privacy;
