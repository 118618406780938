import React, { useEffect } from 'react';
import {
    Typography,
    useMediaQuery
} from "@material-ui/core";
import MetaTags from "react-meta-tags";

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Service() {
    const matches = useMediaQuery("(max-width:1365px)");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <MetaTags>
                <meta name={"description"} content={"Leistungen - Fußpflege & Beauty"} />
                <meta name={"og:title"} content={"Leistungen"} />
            </MetaTags>
            <div className={"body"}>
                <div className={"service_category"}>
                    <Typography variant={"overline"} color={"secondary"} className={"welcome"}>
                        Hauptleistungen
                    </Typography>
                </div>
                {
                    matches ? (
                        <div className={"carouselBody"}>
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={true}
                                infiniteLoop={true}
                                autoPlay={false}
                            >
                                <div className={"service_card"}>
                                    <img alt={""} src={"Fußpflege.jpg"} className={"service_img"}/>
                                    <div className={"service_name"}>Fußbehandlung Basis</div>
                                    <div className={"service_price"}>30 €</div>
                                    <div className={"service_divider"} />
                                    <div className={"service_desc"}>
                                        Entfernung der Hornhaut und Nagelhaut <br/>
                                        Schneiden und Schleifen der Fußnägel <br/>
                                        Abschlusspflege
                                    </div>
                                </div>
                                <div className={"service_card service_card_extra_line"}>
                                    <img alt={""} src={"med.jpg"} className={"service_img"}/>
                                    <div className={"service_name"}>Fußbehandlung Medizinisch</div>
                                    <div className={"service_price"}>ab* 35 €</div>
                                    <div className={"service_addon"}>(*je nach Gesundheitszustand der Füße und der damit verbundene Behandlungsaufwand, fällt der Preis höher aus.)</div>
                                    <div className={"service_divider"} />
                                    <div className={"service_desc"}>
                                        wie "Fußbehandlung Basis" <br/><br/>
                                        + Bearbeitung eingewachsener Fußnägel<br/>
                                        + Entfernung von Hühneraugen<br/>
                                        + Geeignet für Diabetiker<br/>
                                    </div>
                                </div>
                                <div className={"service_card"}>
                                    <img alt={""} src={"pedicure.jpg"} className={"service_img"}/>
                                    <div className={"service_name"}>Fußbehandlung SPA</div>
                                    <div className={"service_price"}>40 €</div>
                                    <div className={"service_divider"} />
                                    <div className={"service_desc"}>
                                        wie "Fußbehandlung Basis" <br/><br/>
                                        + Heißes Fußbad mit Aloe Vera Extrakt <br/>
                                        + Fuß-Peeling (Hautreinigend) <br/>
                                        + Fuß-Maske (Feuchtigkeitsspendend) <br/>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    ) : (
                        <div id={"main_service"}>
                            <div className={"service_card"}>
                                <img alt={""} src={"Fußpflege.jpg"} className={"service_img"}/>
                                <div className={"service_name"}>Fußbehandlung Basis</div>
                                <div className={"service_price"}>30 €</div>
                                <div className={"service_divider"} />
                                <div className={"service_desc"}>
                                    Entfernung der Hornhaut und Nagelhaut <br/>
                                    Schneiden und Schleifen der Fußnägel <br/>
                                    Abschlusspflege
                                </div>
                            </div>
                            <div className={"service_card service_card_extra_line"}>
                                <img alt={""} src={"med.jpg"} className={"service_img"}/>
                                <div className={"service_name"}>Fußbehandlung Medizinisch</div>
                                <div className={"service_price"}>ab* 35 €</div>
                                <div className={"service_addon"}>(*je nach Gesundheitszustand der Füße und der damit verbundene Behandlungsaufwand, fällt der Preis höher aus.)</div>
                                <div className={"service_divider"} />
                                <div className={"service_desc"}>
                                    wie "Fußbehandlung Basis" <br/><br/>
                                    + Bearbeitung eingewachsener Fußnägel<br/>
                                    + Entfernung von Hühneraugen<br/>
                                    + Geeignet für Diabetiker<br/>
                                </div>
                            </div>
                            <div className={"service_card"}>
                                <img alt={""} src={"pedicure.jpg"} className={"service_img"}/>
                                <div className={"service_name"}>Fußbehandlung SPA</div>
                                <div className={"service_price"}>40 €</div>
                                <div className={"service_divider"} />
                                <div className={"service_desc"}>
                                    wie "Fußbehandlung Basis" <br/><br/>
                                    + Heißes Fußbad mit Aloe Vera Extrakt <br/>
                                    + Fuß-Peeling (Hautreinigend) <br/>
                                    + Fuß-Maske (Feuchtigkeitsspendend) <br/>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={"service_category"}>
                    <Typography variant={"overline"} color={"secondary"} className={"welcome"}>
                        Extras
                    </Typography>
                </div>
                {
                    matches ? (
                        <div className={"carouselBody"}>
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={true}
                                infiniteLoop={true}
                                autoPlay={false}
                            >
                                <div className={"service_card"}>
                                    <img alt={""} src={"massage.jpg"} className={"service_img"}/>
                                    <div className={"service_name"}>Fußmassage</div>
                                    <div className={"service_price"}>20 €</div>
                                    <div className={"service_divider"} />
                                    <div className={"service_desc"}>
                                        Dauer: ca. 20 Min.
                                    </div>
                                </div>
                                <div className={"service_card"}>
                                    <img alt={""} src={"nagellack.png"} className={"service_img"}/>
                                    <div className={"service_name"}>Nagellack</div>
                                    <div className={"service_price"}>5 - 10 €</div>
                                    <div className={"service_divider"} />
                                    <div className={"service_desc"}>
                                        Nagellack 5 €<br/>
                                        Nagellack French-Weiß 10 €
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    ) : (
                        <div id={"extras_service"}>
                            <div />
                            <div className={"service_card"}>
                                <img alt={""} src={"massage.jpg"} className={"service_img"}/>
                                <div className={"service_name"}>Fußmassage</div>
                                <div className={"service_price"}>20 €</div>
                                <div className={"service_divider"} />
                                <div className={"service_desc"}>
                                    Dauer: ca. 20 Min.
                                </div>
                            </div>
                            <div className={"service_card"}>
                                <img alt={""} src={"nagellack.png"} className={"service_img"}/>
                                <div className={"service_name"}>Nagellack</div>
                                <div className={"service_price"}>5 - 10 €</div>
                                <div className={"service_divider"} />
                                <div className={"service_desc"}>
                                    Nagellack 5 €<br/>
                                    Nagellack French-Weiß 10 €
                                </div>
                            </div>
                            <div />
                        </div>
                    )
                }
            </div>

        </div>
    );
}

export default Service;
