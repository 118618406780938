import React, { useEffect } from 'react';
import {
    Typography,
} from "@material-ui/core";
import MetaTags from "react-meta-tags";
import Carousel from "react-gallery-carousel";

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <MetaTags>
                <meta name={"description"} content={"Praxis."} />
                <meta name={"og:title"} content={"Praxis"} />
            </MetaTags>
            <div className={"body"}>
                <div className={"topElement"}>
                    <Typography variant={"overline"} color={"secondary"}>
                        Praxis
                    </Typography>
                </div>
                <div id={"praxis"}>
                    <div className={"gallery"}>
                        <Carousel
                            hasThumbnails={false}
                            hasRightButton={false}
                            hasLeftButton={false}
                            isAutoPlaying={true}
                            hasMediaButton={false}
                            hasSizeButton={false}
                            hasIndexBoard={false}
                            hasDotButtons={"bottom"}
                            images={[
                                { src: "praxis_new_1.jpeg" },
                                { src: "praxis_new_2.jpeg" },
                                { src: "praxis_new_3.jpeg" }
                            ]}
                        />
                    </div>
                    <div className={"praxis_points"}>
                        <div className={"praxis_point"}>
                            <div className={"praxis_icon"}>
                                <img alt={""} src={"/img/medal.png"} />
                            </div>
                            <div className={"praxis_desc"}>
                                Zertifizierte Fußpflege
                            </div>
                        </div>
                        <div className={"praxis_point"}>
                            <div className={"praxis_icon"}>
                                <img alt={""} src={"/img/pin.png"} />
                            </div>
                            <div className={"praxis_desc"}>
                                Zentral gelegen in Lohr am Main mit Parkplatzmöglichkeit
                            </div>
                        </div>
                        <div className={"praxis_point"}>
                            <div className={"praxis_icon"}>
                                <img alt={""} src={"/img/gear-option.png"} />
                            </div>
                            <div className={"praxis_desc"}>
                                Qualitativ hochwertige Behandlungsgeräte und Methoden
                            </div>
                        </div>
                        <div className={"praxis_point"}>
                            <div className={"praxis_icon"}>
                                <img alt={""} src={"/img/hygiene.png"} />
                            </div>
                            <div className={"praxis_desc"}>
                                Höchste Hygienestandards
                            </div>
                        </div>
                        <div className={"praxis_point"}>
                            <div className={"praxis_icon"}>
                                <img alt={""} src={"/img/human-footprints.png"} />
                            </div>
                            <div className={"praxis_desc"}>
                                Rundumsorglos-Leistungen für Ihre Füße
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
