import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import moment from "moment";
import "moment/locale/de";
import MomentUtils from "@date-io/moment";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CloseIcon from '@material-ui/icons/Close';

import { getFreeAppointments, saveAppointment } from '../functions/firebase';
import { sendConfirmationEmail } from '../functions/mailer';
import MetaTags from "react-meta-tags";

const TimeControl = (classes) => (props) => (
    <FormControl className={classes.formControl}>
        <InputLabel variant={"outlined"} className={props.error && classes.errorLabel}>Uhrzeit</InputLabel>
        <Select
            {...props}
        />
        {props.error && <FormHelperText error={props.error} className={classes.helperText}>Zeit auswählen</FormHelperText>}
    </FormControl>
);

const PackageControl = (classes) => (props) => {
    const packages = [
        {
            header: "Hauptleistung",
            items: ["Fußbehandlung Basis", "Fußbehandlung Medizinisch", "Fußbehandlung SPA"]
        }
    ]
    return (
        <FormControl className={classes.formControl}>
            <InputLabel variant={"outlined"} className={props.error && classes.errorLabel}>Hauptleistung</InputLabel>
            <Select {...props}>
                {
                    packages.map(p => {
                        return [
                            // <ListSubheader className={classes.subheader}>{p.header}</ListSubheader>,
                            p.items.map(i => (
                                <MenuItem className={classes.menuItem} value={i}>{i}</MenuItem>
                            ))
                        ]
                    })
                }
            </Select>
            {props.error && <FormHelperText error={props.error} className={classes.helperText}>Leistungy auswählen</FormHelperText>}
        </FormControl>
    )
};

const PrivacyControl = (classes) => (props) => (
    <FormControlLabel
        control={
            <Checkbox
                {...props}
            />
        }
        label={
            <Typography>
                {"Ich stimme der "}
                <NavLink className={classes.link} underline={"none"} exact to={"/datenschutz"} target={"_blank"}>
                    {"Datenschutzerklärung"}
                </NavLink>
                {" zu."}
            </Typography>
        }
    />
);

const OptionalControl = (option, classes) => (props) => (
    <FormControl className={classes.formControl}>
        <FormControlLabel
            control={
                <Checkbox
                    {...props}
                />
            }
            label={
                <Typography style={{margin: 0}}>
                    {option}
                </Typography>
            }
        />
        {props.error && <FormHelperText error={props.error} className={classes.helperText}>Leistung auswählen</FormHelperText>}
    </FormControl>
);

function Booking() {
    moment.locale("de");
    const classes = useStyles();
    const { control, errors, handleSubmit, setValue } = useForm();

    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [sentDialogOpen, setSentDialogOpen] = useState(false);
    const [sent, setSent] = useState(false);
    const [slots, setSlots] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        async function updateAppointments() {
            await handleSlotsChange(new moment());
        }
        updateAppointments().then();
    }, [])

    const formatDate = (currentDate) => {
        let formatDay = currentDate.getDate();
        let formatMonth = currentDate.getMonth() + 1;
        let date = `${currentDate.getFullYear()}-`;
        if (formatMonth < 10) {
            date += `0${formatMonth}-`;
        } else {
            date += `${formatMonth}-`;
        }
        if (formatDay < 10) {
            date += `0${formatDay}`;
        } else {
            date += `${formatDay}`;
        }
        return date;
    };

    const onSubmit = async (data) => {
        const slotIndex = slots.findIndex(s => s.day === data.day);
        const sentSuccess = await saveAppointment(data, slots[slotIndex]);
        if (sentSuccess) {
            setSentDialogOpen(true);
            sendConfirmationEmail(data.email, data.day, data.time);
        }
        setSent(sentSuccess);
    };

    const handleSlotsChange = async (date) => {
        const slotData = await getFreeAppointments(date._d.getMonth(), date._d.getFullYear());
        setSlots(slotData);
    };

    const handleDisableDate = (date) => {
        const currentDate = formatDate(date._d);
        const slotIndex = slots.findIndex(s => s.day === currentDate);
        if (slotIndex === -1) {
            return true;
        }
        return slots[slotIndex].times.findIndex(t => !t.busy) === -1;
    };

    const handleTimeSlotsChange = (date) => {
        const [newDate] = date;
        const currentDate = formatDate(date ? newDate._d : new Date());
        setValue("day", currentDate);
        const slotIndex = slots.findIndex(s => s.day === currentDate);
        if (slotIndex === -1) {
            handleTimeSlots([]);
        }
        handleTimeSlots(slots[slotIndex].times, currentDate === formatDate(new Date()));
        return newDate;
    };

    const handleTimeSlots = (slots, isToday) => {
        if (slots.length === 0) {
            setAvailableSlots([]);
        } else {
            let newSlots = [];
            if (isToday) {
                newSlots = slots.sort((a, b) => a.time > b.time).filter(t => !t.busy && t.time > `${new Date().getHours()}`).map(t => (
                    <MenuItem value={t.time}>{t.time}</MenuItem>
                ));
            } else {
                newSlots = slots.sort((a, b) => a.time > b.time).filter(t => !t.busy).map(t => (
                    <MenuItem value={t.time}>{t.time}</MenuItem>
                ));
            }
            setAvailableSlots(newSlots);
        }
        setValue("time", "");
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleSentDialogClose = () => {
        clearForm();
        setSentDialogOpen(false);
    };

    const clearForm = () => {
        setValue("name", "");
        setValue("surname", "");
        setValue("email", "");
        setValue("phone", "");
        setValue("package", "");
        setValue("date", null);
        setValue("time", "");
        setValue("privacy", "");
        setValue("massage", "");
        setValue("lack", "");
        setValue("lack_french", "");
    };

    const testFocus = () => {
        setLoading(false);
    };

    const testBlur = () => {
        setLoading(false);
    }

    return (
        <div>
            <MetaTags>
                <meta name={"description"} content={"Buchen Sie Ihren Termin online."} />
                <meta name={"og:title"} content={"Online Buchung"} />
            </MetaTags>
            {loading && <CircularProgress className={classes.progress} />}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={snackOpen}
                message="Aufgrund der aktuellen Corona-Auflagen ist mit Einschränkungen im Praxisbetrieb zu rechnen. Bitte Hände am Eingang desinfizieren und eine FFP2-Maske während der Behandlung tragen. Bitte beachten Sie auch die 3G-Regeln (geimpft, genesen oder getestet). Vielen Dank für Ihr Verständnis."
                onClose={handleSnackClose}
                action={
                    <IconButton size={"small"} className={classes.snackbar} onClick={handleSnackClose}>
                        <CloseIcon fontSize={"small"} />
                    </IconButton>
                }
                className={classes.snack}
            />
            <Dialog open={sentDialogOpen} onClose={handleSentDialogClose}>
                <DialogTitle disableTypography>
                    <Typography variant={"h4"}>
                        Vielen Dank für Ihre Terminbuchung.
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={"body2"} className={classes.sentDialog}>
                        Sie erhalten eine Bestätigung per E-Mail. Überprüfen Sie auch Ihren Spam-Ordner. Falls die E-Mail nicht bei Ihnen ankommt, rufen Sie uns kurz an. Ihren Termin können Sie bis spätestens 24 Stunden vor der Behandlung kostenlos absagen oder verschieben. Ich freue mich auf Ihren Besuch – Ihre Füße werden es Ihnen danken.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={handleSentDialogClose}>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={"body"}>
                <Grid container alignItems={"flex-start"} justify={"flex-start"} spacing={3}>
                    <Grid item xs={12}>
                        <div className={"topElement"}>
                            <Typography variant={"overline"} color={"secondary"} className={"welcome"}>
                                Buchen Sie Ihren Termin
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={8}>

                            <form onSubmit={handleSubmit(onSubmit)} className={classes.formMain}>
                                <Grid container item xs={12} spacing={3} alignItems={"center"} className={classes.row}>

                                    <Grid item xs={12} md={6} sm={5}>
                                        <Controller
                                            className={classes.formControl}
                                            defaultValue={""}
                                            name={"name"}
                                            as={TextField}
                                            label={"Vorname"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            error={errors.name && true}
                                            control={control}
                                            rules={{required: true}}
                                            helperText={errors.name && "First name is required"}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} sm={5}>
                                        <Controller
                                            className={classes.formControl}
                                            defaultValue={""}
                                            name={"surname"}
                                            as={TextField}
                                            label={"Name"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            error={errors.surname && true}
                                            control={control}
                                            rules={{required: true}}
                                            helperText={errors.surname && "Last name is required"}
                                        />
                                    </Grid>

                                </Grid>

                                <Grid container item xs={12} spacing={3} alignItems={"center"} className={classes.row}>

                                    <Grid item xs={12} md={6} sm={5}>
                                        <Controller
                                            className={classes.formControl}
                                            defaultValue={""}
                                            name={"email"}
                                            as={TextField}
                                            label={"E-Mail"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            error={errors.email && true}
                                            control={control}
                                            rules={{required: true, pattern: /^\S+@\S+[.]\S+$/i}}
                                            onBlur={e => setValue("email", e[0].target.value.trim())}
                                            helperText={errors.email && (errors.email.type === "required" ? "Email is required" : "Email wrong pattern")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} sm={5}>
                                        <Controller
                                            className={classes.formControl}
                                            defaultValue={""}
                                            name={"phone"}
                                            as={TextField}
                                            label={"Telefonnummer"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            error={errors.phone && true}
                                            control={control}
                                            rules={{required: true, pattern: (/^[+]*[0-9]+$/i)}}
                                            onBlur={e => setValue("phone", e[0].target.value.trim())}
                                            helperText={errors.phone && (errors.phone.type === "required" ? "Phone is required" : "Phone wrong pattern")}
                                        />
                                    </Grid>

                                </Grid>

                                <Grid container item xs={12} spacing={3} alignItems={"center"} className={classes.row}>
                                    <Grid item xs={12} md={12} sm={10}>
                                        <Controller
                                            className={classes.formControl}
                                            name={"package"}
                                            as={PackageControl(classes)}
                                            defaultValue={""}
                                            label={"Hauptleistung"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            error={errors.package && true}
                                            control={control}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={3} alignItems={"center"} className={classes.optionalRow}>
                                    <InputLabel shrink className={classes.optionalLabel}>Zur Hauptleistung hinzufügen</InputLabel>
                                    <Grid item xs={12} md={4} sm={4} className={classes.optionalItem}>
                                        <Controller
                                            name={"massage"}
                                            as={OptionalControl("Fußmassage", classes)}
                                            value={"massage"}
                                            defaultValue={false}
                                            color={"secondary"}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={4} className={classes.optionalItem}>
                                        <Controller
                                            name={"lack"}
                                            as={OptionalControl("Nägel lackieren", classes)}
                                            value={"lack"}
                                            defaultValue={false}
                                            color={"secondary"}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={4} className={classes.optionalItem}>
                                        <Controller
                                            name={"lack_french"}
                                            as={OptionalControl("Nägel lackieren mit French-Weiß", classes)}
                                            value={"lack_french"}
                                            defaultValue={false}
                                            color={"secondary"}
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={3} alignItems={"center"} className={classes.row}>

                                    <Grid item xs={12} md={6} sm={5}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Controller
                                                className={classes.formControl}
                                                name={"date"}
                                                as={DatePicker}
                                                label={"Datum"}
                                                variant={"intline"}
                                                inputVariant={"outlined"}
                                                okLabel={"Auswählen"}
                                                cancelLabel={"Zurück"}
                                                format={"DD.MM.yyyy"}
                                                minDate={new Date()}
                                                maxDate={new Date().setMonth(new Date().getMonth() + 4)}
                                                disablePast
                                                defaultValue={null}
                                                error={errors.date && true}
                                                control={control}
                                                rules={{required: true}}
                                                helperText={errors.date && "Termin auswählen"}
                                                InputProps={{
                                                    "onFocus": testFocus
                                                }}
                                                onBlur={testBlur}
                                                onChange={handleTimeSlotsChange}
                                                onMonthChange={handleSlotsChange}
                                                onYearChange={handleSlotsChange}
                                                shouldDisableDate={handleDisableDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <Controller
                                            name={"day"}
                                            as={<div/>}
                                            control={control}
                                            defaultValue={""}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} sm={5}>
                                        <Controller
                                            className={classes.formControl}
                                            name={"time"}
                                            as={TimeControl(classes)}
                                            children={availableSlots}
                                            defaultValue={""}
                                            label={"Uhrzeit"}
                                            variant={"outlined"}
                                            color={"secondary"}
                                            error={errors.time && true}
                                            control={control}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={3} alignItems={"center"} className={classes.row}>
                                    <Grid item>
                                        <Controller
                                            name={"privacy"}
                                            as={PrivacyControl(classes)}
                                            value={"privacy"}
                                            defaultValue={false}
                                            color={"secondary"}
                                            className={errors.privacy && classes.cbError}
                                            control={control}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {errors.privacy && <FormHelperText error className={classes.errorHelperText}>Sie müssen den Datenschutz akzeptieren.</FormHelperText>}
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={3} alignItems={"center"} className={classes.row}>
                                    <Grid item xs={12} md={12} sm={10}>
                                        <Typography variant={"body2"}>
                                            * Können Sie Ihren Termin nicht wahrnehmen, bitte ich Sie diesen mindestens 24 Stunden vor der Behandlung telefonisch abzusagen, ansonsten wird Ihnen 15 EUR in Rechnung gestellt.
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={3} justify={"flex-end"} className={classes.row}>
                                    <Grid item xs={12} md={6} sm={5}>
                                        <Button
                                            type={"submit"}
                                            size={"large"}
                                            variant={"contained"}
                                            color={"secondary"}
                                            disabled={sent}
                                            disableElevation
                                            className={classes.button}
                                        >
                                            {sent ? "Termin gebucht" : "Jetzt Termin buchen"}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={false} md={6} sm={2} />
                                </Grid>

                            </form>

                    </Grid>

                </Grid>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    button: {
        width: "100%",
        borderRadius: 0,
        padding: 15
    },
    cbError: {
        color: "red",
        display: "flex"
    },
    errorHelperText: {
        paddingTop: 0,
        marginTop: -30,
        marginLeft: 14
    },
    formControl: {
        width: "100%",
    },
    errorLabel: {
        color: "red"
    },
    helperText: {
        marginLeft: 14
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.link,
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    menuItem: {
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            whiteSpace: "normal"
        }
    },
    row: {
        marginBottom: 15
    },
    optionalRow: {
        margin: 0,
        marginBottom: 15,
        border: "1px solid",
        borderRadius: 4,
        borderColor: "rgba(0, 0, 0, 0.23)",
        position: "relative"
    },
    optionalLabel: {
        transform: "translate(14px, -6px) scale(0.75)",
        top: 0,
        left: 0,
        position: "absolute",
        backgroundColor: "white",
        padding: "0 5px"
    },
    optionalItem: {
    },
    sentDialog: {
        textAlign: "justify"
    },
    snack: {
        maxWidth: 400,
        textAlign: "justify"
    },
    snackbar: {
        color: "#fff"
    },
    stepper: {
        textAlign: "right",
        '& button': {
            margin: 10
        }
    },
    subheader: {
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        }
    },
    progress: {
        positing: "absolute",
        marginTop: "50%",
        marginLeft: "50%",
        zIndex: 100
    }
}));

export default Booking;
