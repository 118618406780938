import React, { useEffect, useState } from 'react';
import './AdminPortal.css';

import LogIn from "./LogIn";
import Portal from "./Portal";

import { logIn, logOut } from "../functions/firebase";

function AdminPortal() {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const handleLogIn = async (email, password) => {
        const success = await logIn(email, password);
        localStorage.setItem("fe-token", "devbytomas");
        setLoggedIn(success);
    };

    const handleLogOut = async () => {
        await logOut();
        localStorage.removeItem("fe-token");
        setLoggedIn(false);
    }

    return (
        <div>
            {(loggedIn || localStorage.getItem("fe-token") === "devbytomas")? (
                <Portal logOut={handleLogOut} />
            ) : (
                <LogIn logIn={handleLogIn}/>
            )}
        </div>
    );
}

export default AdminPortal;
